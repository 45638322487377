:root {
  /* Colors */
  --color-black: #212529;
  --color-gray-dark: #757575;
  --color-gray: #D8D8D8;
  --color-gray-light: #ececec;
  --color-gray-extra-light: #f6f6f6;
  --color-white: #fff;

  --color-primary-red: 0;
  --color-primary-green: 85;
  --color-primary-blue: 150;
  
  --color-election-center-bg: #302f2f;

  --color-primary: rgb(
    var(--color-primary-red),
    var(--color-primary-green),
    var(--color-primary-blue)
  );
  --color-primary-darkened: #280061;
  --color-primary-lightened: #8E4DC7;
  --color-secondary: #D7CA2A;
  --color-light: #DDEDFF;

  --brand-danger: #E22529;
  --brand-disabled: var(--color-gray-light);
  --brand-success: #008547;
  --brand-warning: #FFA400;

  /* Typography */
  --font-family-base: 'Lato', sans-serif;
  --font-family-headings: 'Lato', sans-serif;

  /* Nav */
  --nav-height: 3.5rem;
  --nav-height--lg: 5rem;

  /* Spinner */
  --spinner-color: var(--color-primary);
  --spinner-color-ring: rgba(
    var(--color-primary-red),
    var(--color-primary-green),
    var(--color-primary-blue),
    .3
  );

  /* Links */
  --link-color: #0156AC;
  --link-color--hover: #175DAF;

/* Global Alert */
  --global-alert-height: 0rem;
  --share-banner-height: 5rem;

  /* Lead Bar */
  --lead-bar-height: 8rem;

  /* CSC: Header */
  --header-background: var(--color-primary);
  --header-color: var(--color-light);

  /* CSC: CardList */
  --card-list-width: 540px;

  /* MAPTV: Header */
  --maptv-header-height: 64px; /* Set fixed height so we can calc step offsets */
}

.RequestBallotApp .LocalNav__GlobalAlert {
display: none !important;
position: unset;
}
.CheckRegistrationApp .LocalNav__GlobalAlert {
display: none !important;
position: unset;
}

@media (min-width: 576px) {
  :root {
    --maptv-header-height: 112px;
  }
}

.LocalNav__Brand { max-height: 8rem; } 

.LocalNav__GlobalAlert {
    display: none !important;
}

@media (min-width: 768px) {
.VoterGuideApp--has-alert {
    margin-top: var(--nav-height--lg) !important;
  }
}

.QuestionsAboutVoting_votingQuestions__a4f-K:visited, .QuestionsAboutVoting_votingQuestionsLink__YpNN_ {
    display: none !important;
}
.MaptvApp .LocalNav__GlobalAlert {
    display: none !important;
    position: unset !important;
}
    /* Share Scene */
    :root {
      --share-image-background: #326272;
      --share-image-text: #e39674;
      --share-image-label-text: #ffffff;
    }